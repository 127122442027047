<template>
  <nav>
    <PreLoader v-if="isLoading" />
    <input type="checkbox" id="show-menu" @click="changeMenuStatus" />
    <label for="show-menu" class="menu-icon"><i class="fa fa-bars"></i></label>
    <!-- <span class="logo-icon" :title="$t('projectName')">
      <img src="@/assets/images/logo.png" class="logo" />
    </span> -->
    <div class="page-name">
      <!-- <img src="@/assets/images/homepage.svg" /> -->
      <img :src="require('@/assets/images/' + pageIcon)" />
      <h3>{{ pageName }}</h3>
    </div>
    <div class="user-icon" v-if="userData">
      <b-dropdown id="dropdown-user">
        <template #button-content>
          <img
            :src="employeeImage"
            :onerror="`this.src='${defaultImg}'`"
            alt="images"
          />
        </template>
        <div class="text-center">
          <img
            :src="employeeImage"
            :onerror="`this.src='${defaultImg}'`"
            alt="images"
            class="img-profile"
          />
          <b-dropdown-item>{{ employeeName }}</b-dropdown-item>
        </div>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item v-if="language == 'en'" @click="changeLanguage('ar')">
          <img src="@/assets/images/lang-ar.svg" />
          العربية
        </b-dropdown-item>
        <b-dropdown-item v-if="language == 'ar'" @click="changeLanguage('en')">
          <img src="@/assets/images/lang-en.svg" />
          English
        </b-dropdown-item>
        <!-- <b-dropdown-item v-if="theme == 'dark'" @click="changeTheme('light')">
          <img src="@/assets/images/mode-light.svg" />
          {{ $t("lightMode") }}
        </b-dropdown-item>
        <b-dropdown-item v-if="theme == 'light'" @click="changeTheme('dark')">
          <img src="@/assets/images/mode-dark.svg" />
          {{ $t("darkMode") }}
        </b-dropdown-item> -->
        <b-dropdown-item v-b-modal.UserChangePassword>
          <img src="@/assets/images/changePassword.svg" />
          {{ $t("changePassword") }}
        </b-dropdown-item>
        <b-dropdown-item v-b-modal.UserLogOut>
          <img src="@/assets/images/logout.svg" />
          {{ $t("logout") }}
        </b-dropdown-item>
        <!-- <b-dropdown-item active>Active action</b-dropdown-item> -->
      </b-dropdown>
    </div>

    <UserChangePassword v-on:logout="logout" />
    <UserLogOut v-on:logout="logout" />
  </nav>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import { BASE_URL, DEFAULT_IMG } from "@/utils/constants";
import { mapGetters } from "vuex";
import UserChangePassword from "@/components/user/UserChangePassword.vue";
import UserLogOut from "@/components/user/UserLogOut.vue";

export default {
  name: "Navbar",
  components: {
    PreLoader,
    UserChangePassword,
    UserLogOut,
  },
  data() {
    return {
      isLoading: false,
      language: localStorage.getItem("userLanguage") || "ar",
      theme: localStorage.getItem("userTheme") || "light",
      checked: false,
      pageName: this.$t("homePage"),
      pageIcon: "homepage.svg",
      defaultImg: DEFAULT_IMG,
    };
  },
  methods: {
    logout() {
      this.isLoading = true;
      this.$store.dispatch("updateUserData", null);
      this.$router.push("/").catch(() => {});
    },
    changeLanguage(lang) {
      this.isLoading = true;
      localStorage.setItem("userLanguage", lang);
      window.location.reload();
    },
    changeTheme(theme) {
      this.isLoading = true;
      localStorage.setItem("userTheme", theme);
      window.location.reload();
    },
    changeMenuStatus() {
      this.$emit("changeMenuStatus");
    },
    getCurrentPage(path) {
      if (path == "/") {
        this.pageName = this.$t("homePage");
        this.pageIcon = "homepage.svg";
      } else if (path.indexOf("/employee-slices") == 0) {
        this.pageName = this.$t("slices");
        this.pageIcon = "slices.svg";
      } else if (path.indexOf("/add-employee-slice") == 0) {
        this.pageName = this.$t("addSlice");
        this.pageIcon = "slices.svg";
      } else if (path.indexOf("/employee-salary-settings") == 0) {
        this.pageName = this.$t("EmployeeSalarySetting");
        this.pageIcon = "employee-salary-setting.svg";
      } else if (path.indexOf("/add-employee-salary-setting") == 0) {
        this.pageName = this.$t("EmployeeSalarySettingAdd");
        this.pageIcon = "employee-salary-setting.svg";
      } else if (path.indexOf("/edit-employee-salary-setting") == 0) {
        this.pageName = this.$t("EmployeeSalarySettingEdit");
        this.pageIcon = "employee-salary-setting.svg";
      } else if (path.indexOf("/employee-work-schedules") == 0) {
        this.pageName = this.$t("EmployeeWorkSchedule");
        this.pageIcon = "work-schedule.svg";
      } else if (path.indexOf("/add-employee-work-schedule") == 0) {
        this.pageName = this.$t("EmployeeWorkScheduleAdd");
        this.pageIcon = "work-schedule.svg";
      } else if (path.indexOf("/employee-official-holidays-plans") == 0) {
        this.pageName = this.$t("EmployeeOfficialHolidaysPlans");
        this.pageIcon = "holiday.svg";
      } else if (path.indexOf("/add-employee-official-holidays-plan") == 0) {
        this.pageName = this.$t("EmployeeOfficialHolidaysPlanAdd");
        this.pageIcon = "holiday.svg";
      } else if (
        path.indexOf("/employees-input-sheet-attendance-merges") == 0
      ) {
        this.pageName = this.$t("employeesInputSheetAttendanceMerges");
        this.pageIcon = "merge.svg";
      } else if (
        path.indexOf("/add-employees-input-sheet-attendance-merge") == 0
      ) {
        this.pageName = this.$t("employeesInputSheetAttendanceMergeAdd");
        this.pageIcon = "merge.svg";
      } else if (path.indexOf("/employees-input-sheets-attendance") == 0) {
        this.pageName = this.$t("employeesInputSheetsAttendance");
        this.pageIcon = "sheets.svg";
      } else if (path.indexOf("/add-employees-input-sheet-attendance") == 0) {
        this.pageName = this.$t("employeesInputSheetAttendanceAdd");
        this.pageIcon = "sheets.svg";
      } else if (path.indexOf("/employees") == 0) {
        this.pageName = this.$t("theEmployees");
        this.pageIcon = "employees.svg";
      } else if (path.indexOf("/add-employee") == 0) {
        this.pageName = this.$t("employeeAdd");
        this.pageIcon = "addUser.svg";
      } else if (path.indexOf("/edit-employee") == 0) {
        this.pageName = this.$t("employeeEdit");
        this.pageIcon = "pencil.svg";
      } else if (path.indexOf("/attendance-employee") == 0) {
        this.pageName = this.$t("attendanceHistory");
        this.pageIcon = "attendance.svg";
      } else if (path.indexOf("/add-attendance-employee") == 0) {
        this.pageName = this.$t("attendanceAdd");
        this.pageIcon = "plus.svg";
      } else if (path.indexOf("/edit-attendance-employee") == 0) {
        this.pageName = this.$t("attendanceEdit");
        this.pageIcon = "pencil.svg";
      } else if (path.indexOf("/movement-employee") == 0) {
        this.pageName = this.$t("employeeMovement");
        this.pageIcon = "address.svg";
      } else if (path.indexOf("/add-movement-employee") == 0) {
        this.pageName = this.$t("movementAdd");
        this.pageIcon = "plus.svg";
      } else if (path.indexOf("/edit-movement-employee") == 0) {
        this.pageName = this.$t("movementEdit");
        this.pageIcon = "pencil.svg";
      } else if (path.indexOf("/employee-movement-media") == 0) {
        this.pageName = this.$t("media");
        this.pageIcon = "media.svg";
      } else if (path.indexOf("/attendance-history") == 0) {
        this.pageName = this.$t("attendanceAndDepartureHistory");
        this.pageIcon = "attendanceHistory.svg";
      } else if (path.indexOf("/add-attendance") == 0) {
        this.pageName = this.$t("checkIn");
        this.pageIcon = "add-attendance.svg";
      } else if (path.indexOf("/add-departure") == 0) {
        this.pageName = this.$t("checkOut");
        this.pageIcon = "add-departure.svg";
      } else if (path.indexOf("/points") == 0) {
        this.pageName = this.$t("points");
        this.pageIcon = "points.svg";
      } else if (path.indexOf("/employee-points") == 0) {
        this.pageName = this.$t("employeePoints");
        this.pageIcon = "points.svg";
      } else if (path.indexOf("/reports") == 0) {
        this.pageName = this.$t("reports");
        this.pageIcon = "reports.svg";
      } else if (path.indexOf("/privilege") == 0) {
        this.pageName = this.$t("privilege");
        this.pageIcon = "privilege.svg";
      } else if (path.indexOf("/branches-times") == 0) {
        this.pageName = this.$t("branchesTimes");
        this.pageIcon = "time.svg";
      } else if (path.indexOf("/add-branch-time") == 0) {
        this.pageName = this.$t("branchTimeAdd");
        this.pageIcon = "time.svg";
      } else if (path.indexOf("/edit-branch-time") == 0) {
        this.pageName = this.$t("branchTimeEdit");
        this.pageIcon = "time.svg";
      } else if (path.indexOf("/branches") == 0) {
        this.pageName = this.$t("theBranches");
        this.pageIcon = "branches.svg";
      } else if (path.indexOf("/add-branch") == 0) {
        this.pageName = this.$t("branchAdd");
        this.pageIcon = "branches.svg";
      } else if (path.indexOf("/edit-branch") == 0) {
        this.pageName = this.$t("branchEdit");
        this.pageIcon = "branches.svg";
      } else if (path.indexOf("/stores-times") == 0) {
        this.pageName = this.$t("storesTimes");
        this.pageIcon = "time.svg";
      } else if (path.indexOf("/add-store-time") == 0) {
        this.pageName = this.$t("storeTimeAdd");
        this.pageIcon = "time.svg";
      } else if (path.indexOf("/edit-store-time") == 0) {
        this.pageName = this.$t("storeTimeEdit");
        this.pageIcon = "time.svg";
      } else if (path.indexOf("/stores") == 0) {
        this.pageName = this.$t("theStores");
        this.pageIcon = "stores.svg";
      } else if (path.indexOf("/add-store") == 0) {
        this.pageName = this.$t("storeAdd");
        this.pageIcon = "stores.svg";
      } else if (path.indexOf("/edit-store") == 0) {
        this.pageName = this.$t("storeEdit");
        this.pageIcon = "stores.svg";
      } else if (path.indexOf("/treasuries-transactions") == 0) {
        this.pageName = this.$t("treasuriesTransactions");
        this.pageIcon = "treasuries-transactions.svg";
      } else if (path.indexOf("/add-treasury-transaction") == 0) {
        this.pageName = this.$t("treasuryTransactionAdd");
        this.pageIcon = "treasuries-transactions.svg";
      } else if (path.indexOf("/treasuries") == 0) {
        this.pageName = this.$t("theTreasuries");
        this.pageIcon = "treasuries.svg";
      } else if (path.indexOf("/add-treasury") == 0) {
        this.pageName = this.$t("treasuryAdd");
        this.pageIcon = "treasuries.svg";
      } else if (path.indexOf("/edit-treasury") == 0) {
        this.pageName = this.$t("treasuryEdit");
        this.pageIcon = "treasuries.svg";
      } else if (path.indexOf("/clients") == 0) {
        this.pageName = this.$t("clients");
        this.pageIcon = "clients.svg";
      } else if (path.indexOf("/add-client") == 0) {
        this.pageName = this.$t("clientAdd");
        this.pageIcon = "clients.svg";
      } else if (path.indexOf("/edit-client") == 0) {
        this.pageName = this.$t("clientEdit");
        this.pageIcon = "clients.svg";
      } else if (path.indexOf("/settings-establishment") == 0) {
        this.pageName = this.$t("settings-establishment");
        this.pageIcon = "settings-establishment.svg";
      } else if (path.indexOf("/months-establishment-settings") == 0) {
        this.pageName = this.$t("establishmentMonthsSettings");
        this.pageIcon = "months-settings.svg";
      } else if (path.indexOf("/add-months-establishment-setting") == 0) {
        this.pageName = this.$t("establishmentMonthsSettingAdd");
        this.pageIcon = "months-settings.svg";
      } else if (path.indexOf("/year-establishment-settings") == 0) {
        this.pageName = this.$t("establishmentYearSettings");
        this.pageIcon = "year-settings.svg";
      } else if (path.indexOf("/add-year-establishment-setting") == 0) {
        this.pageName = this.$t("establishmentYearSettingAdd");
        this.pageIcon = "year-settings.svg";
      } else if (path.indexOf("/edit-year-establishment-setting") == 0) {
        this.pageName = this.$t("establishmentYearSettingEdit");
        this.pageIcon = "year-settings.svg";
      } else if (path.indexOf("/settings-other") == 0) {
        this.pageName = this.$t("settings-other");
        this.pageIcon = "settings-other.svg";
      } else if (path.indexOf("/sectors") == 0) {
        this.pageName = this.$t("sectors");
        this.pageIcon = "sectors.svg";
      } else if (path.indexOf("/jobs") == 0) {
        this.pageName = this.$t("jobs");
        this.pageIcon = "jobs.svg";
      } else if (path.indexOf("/qualifications") == 0) {
        this.pageName = this.$t("qualifications");
        this.pageIcon = "qualifications.svg";
      } else if (path.indexOf("/revenues-types") == 0) {
        this.pageName = this.$t("revenuesTypes");
        this.pageIcon = "revenues-types.svg";
      } else if (path.indexOf("/add-revenue-type") == 0) {
        this.pageName = this.$t("revenueTypeAdd");
        this.pageIcon = "revenues-types.svg";
      } else if (path.indexOf("/edit-revenue-type") == 0) {
        this.pageName = this.$t("revenueTypeEdit");
        this.pageIcon = "revenues-types.svg";
      } else if (path.indexOf("/expenses-types") == 0) {
        this.pageName = this.$t("expensesTypes");
        this.pageIcon = "expenses-types.svg";
      } else if (path.indexOf("/add-expense-type") == 0) {
        this.pageName = this.$t("expenseTypeAdd");
        this.pageIcon = "expenses-types.svg";
      } else if (path.indexOf("/edit-expense-type") == 0) {
        this.pageName = this.$t("expenseTypeEdit");
        this.pageIcon = "expenses-types.svg";
      } else if (path.indexOf("/work-shifts") == 0) {
        this.pageName = this.$t("workShifts");
        this.pageIcon = "work-shifts.svg";
      } else if (path.indexOf("/settings-salary") == 0) {
        this.pageName = this.$t("settings-salary");
        this.pageIcon = "settings-salary.svg";
      } else if (path.indexOf("/add-work-shift") == 0) {
        this.pageName = this.$t("workShiftAdd");
        this.pageIcon = "work-shifts.svg";
      } else if (path.indexOf("/edit-work-shift") == 0) {
        this.pageName = this.$t("workShiftEdit");
        this.pageIcon = "work-shifts.svg";
      } else if (path.indexOf("/work-plans") == 0) {
        this.pageName = this.$t("workPlans");
        this.pageIcon = "work-plans.svg";
      } else if (path.indexOf("/add-work-shift") == 0) {
        this.pageName = this.$t("workPlanAdd");
        this.pageIcon = "work-plans.svg";
      } else if (path.indexOf("/edit-work-shift") == 0) {
        this.pageName = this.$t("workPlanEdit");
        this.pageIcon = "work-plans.svg";
      } else if (path.indexOf("/work-plan-days") == 0) {
        this.pageName = this.$t("workPlanDays");
        this.pageIcon = "work-plan-days.svg";
      } else if (path.indexOf("/salary-effects") == 0) {
        this.pageName = this.$t("salaryEffects");
        this.pageIcon = "salary-effects.svg";
      } else if (path.indexOf("/add-salary-effect") == 0) {
        this.pageName = this.$t("salaryEffectAdd");
        this.pageIcon = "salary-effects.svg";
      } else if (path.indexOf("/edit-salary-effect") == 0) {
        this.pageName = this.$t("salaryEffectEdit");
        this.pageIcon = "salary-effects.svg";
      } else if (path.indexOf("/balances-plans") == 0) {
        this.pageName = this.$t("balancesPlans");
        this.pageIcon = "balances-plans.svg";
      } else if (path.indexOf("/add-balances-plan") == 0) {
        this.pageName = this.$t("balancesPlanAdd");
        this.pageIcon = "balances-plans.svg";
      } else if (path.indexOf("/balances-plan-details") == 0) {
        this.pageName = this.$t("balancesPlanDetails");
        this.pageIcon = "balances-plans.svg";
      } else if (path.indexOf("/add-allowances-plan-details") == 0) {
        this.pageName = this.$t("allowancesPlanDetailsAdd");
        this.pageIcon = "allowances-plans.svg";
      } else if (path.indexOf("/edit-allowances-plan-details") == 0) {
        this.pageName = this.$t("allowancesPlanDetailsEdit");
        this.pageIcon = "allowances-plans.svg";
      } else if (path.indexOf("/allowances-plan-details") == 0) {
        this.pageName = this.$t("allowancesPlanDetails");
        this.pageIcon = "allowances-plans.svg";
      } else if (path.indexOf("/add-allowances-plan") == 0) {
        this.pageName = this.$t("allowancesPlanAdd");
        this.pageIcon = "allowances-plans.svg";
      } else if (path.indexOf("/edit-allowances-plan") == 0) {
        this.pageName = this.$t("allowancesPlanEdit");
        this.pageIcon = "allowances-plans.svg";
      } else if (path.indexOf("/allowances-plans") == 0) {
        this.pageName = this.$t("allowancesPlans");
        this.pageIcon = "allowances-plans.svg";
      } else if (path.indexOf("/add-month-allowances-plan-details") == 0) {
        this.pageName = this.$t("monthAllowancesPlanDetailsAdd");
        this.pageIcon = "month-allowances-plans.svg";
      } else if (path.indexOf("/edit-month-allowances-plan-details") == 0) {
        this.pageName = this.$t("monthAllowancesPlanDetailsEdit");
        this.pageIcon = "month-allowances-plans.svg";
      } else if (path.indexOf("/month-allowances-plan-details") == 0) {
        this.pageName = this.$t("monthAllowancesPlanDetails");
        this.pageIcon = "month-allowances-plans.svg";
      } else if (path.indexOf("/add-month-allowances-plan") == 0) {
        this.pageName = this.$t("monthAllowancesPlanAdd");
        this.pageIcon = "month-allowances-plans.svg";
      } else if (path.indexOf("/edit-month-allowances-plan") == 0) {
        this.pageName = this.$t("monthAllowancesPlanEdit");
        this.pageIcon = "month-allowances-plans.svg";
      } else if (path.indexOf("/month-allowances-plans") == 0) {
        this.pageName = this.$t("monthAllowancesPlans");
        this.pageIcon = "month-allowances-plans.svg";
      } else if (path.indexOf("/allowances") == 0) {
        this.pageName = this.$t("allowances");
        this.pageIcon = "allowances.svg";
      } else if (path.indexOf("/add-allowance") == 0) {
        this.pageName = this.$t("allowanceAdd");
        this.pageIcon = "allowances.svg";
      } else if (path.indexOf("/edit-allowance") == 0) {
        this.pageName = this.$t("allowanceEdit");
        this.pageIcon = "allowances.svg";
      } else if (path.indexOf("/overtime-plans") == 0) {
        this.pageName = this.$t("overTimePlans");
        this.pageIcon = "overtime-plans.svg";
      } else if (path.indexOf("/add-overtime-plan") == 0) {
        this.pageName = this.$t("overTimePlanAdd");
        this.pageIcon = "overtime-plans.svg";
      } else if (path.indexOf("/overtime-plan-details") == 0) {
        this.pageName = this.$t("overTimePlanDetails");
        this.pageIcon = "overtime-plans.svg";
      } else if (path.indexOf("/absence-plans") == 0) {
        this.pageName = this.$t("absencePlans");
        this.pageIcon = "absence-plans.svg";
      } else if (path.indexOf("/add-absence-plan") == 0) {
        this.pageName = this.$t("absencePlanAdd");
        this.pageIcon = "absence-plans.svg";
      } else if (path.indexOf("/absence-plan-details") == 0) {
        this.pageName = this.$t("absencePlanDetails");
        this.pageIcon = "absence-plans.svg";
      } else if (path.indexOf("/main-columns") == 0) {
        this.pageName = this.$t("mainColumns");
        this.pageIcon = "main-columns.svg";
      } else if (path.indexOf("/add-main-column") == 0) {
        this.pageName = this.$t("mainColumnAdd");
        this.pageIcon = "main-columns.svg";
      } else if (path.indexOf("/main-column-details") == 0) {
        this.pageName = this.$t("mainColumnEdit");
        this.pageIcon = "main-columns.svg";
      } else if (path.indexOf("/insurances-plans") == 0) {
        this.pageName = this.$t("insurancesPlans");
        this.pageIcon = "insurances-plans.svg";
      } else if (path.indexOf("/insurances-plan-details") == 0) {
        this.pageName = this.$t("insurancesPlanDetails");
        this.pageIcon = "insurances-plans.svg";
      } else if (path.indexOf("/add-insurances-plan-details") == 0) {
        this.pageName = this.$t("insurancesPlanDetailsAdd");
        this.pageIcon = "insurances-plans.svg";
      } else if (path.indexOf("/edit-insurances-plan-details") == 0) {
        this.pageName = this.$t("insurancesPlanDetailsEdit");
        this.pageIcon = "insurances-plans.svg";
      } else if (path.indexOf("/add-insurances-plan") == 0) {
        this.pageName = this.$t("insurancesPlanAdd");
        this.pageIcon = "insurances-plans.svg";
      } else if (path.indexOf("/edit-insurances-plan") == 0) {
        this.pageName = this.$t("insurancesPlanEdit");
        this.pageIcon = "insurances-plans.svg";
      } else if (path.indexOf("/official-holidays-plans") == 0) {
        this.pageName = this.$t("officialHolidaysPlans");
        this.pageIcon = "official-holidays-plans.svg";
      } else if (path.indexOf("/official-holidays-plan-details") == 0) {
        this.pageName = this.$t("officialHolidaysPlanDetails");
        this.pageIcon = "official-holidays-plans.svg";
      } else if (path.indexOf("/add-official-holidays-plan-details") == 0) {
        this.pageName = this.$t("officialHolidaysPlanDetailsAdd");
        this.pageIcon = "official-holidays-plans.svg";
      } else if (path.indexOf("/edit-official-holidays-plan-details") == 0) {
        this.pageName = this.$t("officialHolidaysPlanDetailsEdit");
        this.pageIcon = "official-holidays-plans.svg";
      } else if (path.indexOf("/add-official-holidays-plan") == 0) {
        this.pageName = this.$t("officialHolidaysPlanAdd");
        this.pageIcon = "official-holidays-plans.svg";
      } else if (path.indexOf("/edit-official-holidays-plan") == 0) {
        this.pageName = this.$t("officialHolidaysPlanEdit");
        this.pageIcon = "official-holidays-plans.svg";
      } else if (path.indexOf("/official-holidays") == 0) {
        this.pageName = this.$t("officialHolidays");
        this.pageIcon = "holiday.svg";
      } else if (path.indexOf("/add-official-holiday") == 0) {
        this.pageName = this.$t("officialHolidayAdd");
        this.pageIcon = "holiday.svg";
      } else if (path.indexOf("/edit-official-holiday") == 0) {
        this.pageName = this.$t("officialHolidayEdit");
        this.pageIcon = "holiday.svg";
      } else if (path.indexOf("/calculate-payroll") == 0) {
        this.pageName = this.$t("calculatePayroll");
        this.pageIcon = "payroll.svg";
      } else if (path.indexOf("/revenues") == 0) {
        this.pageName = this.$t("revenues");
        this.pageIcon = "revenues.svg";
      } else if (path.indexOf("/add-revenue") == 0) {
        this.pageName = this.$t("revenueAdd");
        this.pageIcon = "revenues.svg";
      } else if (path.indexOf("/expenses") == 0) {
        this.pageName = this.$t("expenses");
        this.pageIcon = "expenses.svg";
      } else if (path.indexOf("/add-expense") == 0) {
        this.pageName = this.$t("expenseAdd");
        this.pageIcon = "expenses.svg";
      }
    },
  },
  computed: {
    ...mapGetters(["userData"]),
    employeeName: function () {
      if (this.userData) {
        return this.language == "ar"
          ? this.userData.employeePersonalData.employeeNameAr
          : this.userData.employeePersonalData.employeeNameEn;
      } else {
        return null;
      }
    },
    employeeImage: function () {
      return BASE_URL + this.userData.employeePersonalData.employeeImagePath;
    },
  },
  // async created() {
  //   this.employeeProfileData = JSON.parse(
  //     localStorage.getItem("employeeProfileData")
  //   );
  //   console.log(this.employeeProfileData);
  // },
  watch: {
    $route(to) {
      this.getCurrentPage(to.path);
    },
  },
  async created() {
    this.getCurrentPage(this.$route.path);
  },
};
</script>

<style lang="scss">
#dropdown-user button {
  padding: 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: inherit;
  position: relative;
  border: initial;
  // top: -6px;
  // left: 6px;
}
#dropdown-user button::after {
  border: initial;
}
// #dropdown-user button::after {
//   background-image: url("../../assets/images/man.svg");
//   background-size: 35px;
//   display: inline-block;
//   width: 35px;
//   height: 35px;
//   content: "";
// }
#dropdown-user ul {
  width: 220px;
  // transform: translate3d(0px, 29px, 0px) !important;
  // transform: translate3d(-190px, 29px, 0px) !important;
  // right: var(--position-right-status) !important;
  // left: var(--position-left-status) !important;
}
.user-icon .dropdown-menu li img {
  width: 28px;
  height: 28px;
  border-radius: inherit;
  position: absolute;
  right: 6px;
}
.user-icon .img-profile {
  width: 150px;
  height: initial;
  min-height: 150px;
  max-height: 190px;
  border-radius: inherit;
  margin-bottom: 1rem;
}
</style>
