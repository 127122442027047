<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="addOrUpdateService()"
    >
      <div class="row">
        <div
          class="imgView"
          :style="{ 'background-image': `url(${src})` }"
          :onerror="`this.src='${defaultImg}'`"
          alt="Image"
          @click="selectImage"
        ></div>
        <!-- @input="pickFile" -->
        <input
          ref="fileInput"
          type="file"
          v-on:change="pickFile"
          class="d-none"
          accept=".jpg, .jpeg, .png"
        />
      </div>
      <div class="my-card">
        <div class="row">
          <div class="form-label-group col-6">
            <b-form-select
              v-model="serviceData.serviceTypeToken"
              :options="serviceTypeTokenOptions"
              class="form-control"
            ></b-form-select>
            <img src="@/assets/images/services-types.svg" />
          </div>
          <div class="form-label-group col-6">
            <b-form-select
              v-model="serviceData.specialSpecialtyToken"
              :options="specialSpecialtyTokenOptions"
              class="form-control"
            ></b-form-select>
            <img src="@/assets/images/special-spcialities.svg" />
          </div>
          <!-- name -->
          <CustomInput
            :className="'col-md-4'"
            :id="'serviceNameAr'"
            :value="serviceData.serviceNameAr"
            :title="$t('serviceNameAr')"
            :imgName="'services.svg'"
            v-on:changeValue="serviceData.serviceNameAr = $event"
          />
          <CustomInput
            :className="'col-md-4'"
            :id="'serviceNameEn'"
            :value="serviceData.serviceNameEn"
            :title="$t('serviceNameEn')"
            :imgName="'services.svg'"
            v-on:changeValue="serviceData.serviceNameEn = $event"
          />
          <CustomInput
            :className="'col-md-4'"
            :id="'serviceNameUnd'"
            :value="serviceData.serviceNameUnd"
            :title="$t('serviceNameUnd')"
            :imgName="'services.svg'"
            v-on:changeValue="serviceData.serviceNameUnd = $event"
          />
          <!-- description -->
          <TextArea
            :className="'col-md-4'"
            :id="'serviceDescriptionAr'"
            :value="serviceData.serviceDescriptionAr"
            :title="$t('serviceDescriptionAr')"
            :imgName="'description.svg'"
            v-on:changeValue="serviceData.serviceDescriptionAr = $event"
          />
          <TextArea
            :className="'col-md-4'"
            :id="'serviceDescriptionEn'"
            :value="serviceData.serviceDescriptionEn"
            :title="$t('serviceDescriptionEn')"
            :imgName="'description.svg'"
            v-on:changeValue="serviceData.serviceDescriptionEn = $event"
          />
          <TextArea
            :className="'col-md-4'"
            :id="'serviceDescriptionUnd'"
            :value="serviceData.serviceDescriptionUnd"
            :title="$t('serviceDescriptionUnd')"
            :imgName="'description.svg'"
            v-on:changeValue="serviceData.serviceDescriptionUnd = $event"
          />
          <!-- price -->
          <CustomInputFloat
            :className="'col-md-5'"
            :id="'servicePrice'"
            :value="serviceData.servicePrice"
            :title="$t('servicePrice')"
            :imgName="'money.svg'"
            v-on:changeValue="serviceData.servicePrice = $event"
          />
          <!-- servicePriceInOffer -->
          <CustomInputFloat
            :className="'col-md-5'"
            :id="'servicePriceInOffer'"
            :value="serviceData.servicePriceInOffer"
            :title="$t('servicePriceInOffer')"
            :imgName="'price-offer.svg'"
            v-on:changeValue="serviceData.servicePriceInOffer = $event"
          />
          <!-- offerStatus -->
          <CustomCheckbox
            :className="'col-2'"
            :value="serviceData.offerStatus"
            :title="$t('offerStatus')"
            v-on:changeValue="serviceData.offerStatus = $event"
          />
          <!-- serviceTimeWithMinutes -->
          <CustomInputInt
            :className="'col-md-6'"
            :id="'serviceTimeWithMinutes'"
            :value="serviceData.serviceTimeWithMinutes"
            :title="$t('serviceTimeWithMinutes')"
            :imgName="'minutes.svg'"
            v-on:changeValue="serviceData.serviceTimeWithMinutes = $event"
          />
          <!-- maxDiscountPercentage -->
          <CustomInputFloat
            :className="'col-md-6'"
            :id="'maxDiscountPercentage'"
            :value="serviceData.maxDiscountPercentage"
            :title="$t('maxDiscountPercentage')"
            :imgName="'maxDiscountPercentage.svg'"
            v-on:changeValue="serviceData.maxDiscountPercentage = $event"
          />
          <!-- serviceNotes -->
          <TextArea
            :className="'col-md-12'"
            :id="'serviceNotes'"
            :value="serviceData.serviceNotes"
            :title="$t('notes')"
            :imgName="'notes.svg'"
            v-on:changeValue="serviceData.serviceNotes = $event"
          />
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link to="/services" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomInputInt from "@/components/general/CustomInputInt.vue";
import CustomCheckbox from "@/components/general/CustomCheckbox.vue";
import CustomInputFloat from "@/components/general/CustomInputFloat.vue";
import TextArea from "@/components/general/TextArea.vue";
import { STATUS } from "@/utils/constants";
import DEFAULT_SERVICES_IMG from "@/assets/images/services.svg";
import axios from "axios";

export default {
  name: "ServiceForm",
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInput,
    CustomInputInt,
    CustomCheckbox,
    CustomInputFloat,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      defaultImg: DEFAULT_SERVICES_IMG,
      src: this.imageSrc,
      specialSpecialtyTokenOptions: [],
      serviceTypeTokenOptions: [],
    };
  },
  props: ["serviceData", "imageSrc", "submitName"],
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.src = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
        this.serviceData.image = file[0];
      } else {
        this.src = DEFAULT_SERVICES_IMG;
        this.serviceData.image = "";
      }
      // console.log(this.imageSrc);
    },
    async changeSector() {
      this.serviceData.employeeJobToken = "";
      this.getDialogOfJobs(this.serviceData.employeeSectorToken);
    },
    async addOrUpdateService() {
      this.$emit("addOrUpdateService", this.serviceData);
    },
    async getDialogOfSpecialSpecialties() {
      this.isLoading = true;
      this.specialSpecialtyTokenOptions = [];
      this.specialSpecialtyTokenOptions.push({
        value: "",
        text: this.$t("selectSpecialSpecialty"),
      });
      try {
        const response = await axios.get(
          `/SpecialSpecialties/GetDialogOfSpecialSpecialties?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.specialSpecialtyTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text:
                this.language == "en"
                  ? itemsData[item]["itemNameEn"]
                  : itemsData[item]["itemNameAr"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("updateUserData", null);
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.specialSpecialtyTokenOptions = null;
      }
      this.isLoading = false;
    },
    async getDialogOfServicesTypes() {
      this.isLoading = true;
      this.serviceTypeTokenOptions = [];
      this.serviceTypeTokenOptions.push({
        value: "",
        text: this.$t("selectServiceType"),
      });
      try {
        const response = await axios.get(
          `/ServicesTypes/GetDialogOfServicesTypes?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.serviceTypeTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text:
                this.language == "en"
                  ? itemsData[item]["itemNameEn"]
                  : itemsData[item]["itemNameAr"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("updateUserData", null);
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.serviceTypeTokenOptions = null;
      }
      this.isLoading = false;
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      if (lang == "en") return dataEn;
      else return dataAr;
    },
  },
  watch: {},
  async created() {
    this.getDialogOfSpecialSpecialties();
    this.getDialogOfServicesTypes();
  },
};
</script>

<style lang="scss"></style>
