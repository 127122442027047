// export const BASE_URL = "http://192.168.0.106:44314/";
export const BASE_URL = "https://api-huawei-demo-v1.0.adminssw.com/";
// export const BASE_URL = "https://api-medical-center-management-demo-v1.1.premcoprecast.com/";
// export const BASE_URL = "https://api-medicalcentermanagement-v0.1.adminssw.com/";
// export const BASE_URL = "https://api-premco-v1.adminssw.com/";
// export const BASE_URL = "https://api-employeesmovement-v1.refitegypt.com/";
// export const BASE_URL = "https://192.168.1.2:80/";

export const DEFAULT_IMG = BASE_URL + "/_DefaultFiles/employee.png";
export const DEFAULT_IMG_NEWS = BASE_URL + "/_DefaultFiles/employee.png";
export const DEFAULT_IMG_BRANCH = BASE_URL + "/_DefaultFiles/defaultBranch.png";
export const DEFAULT_IMG_STORE = BASE_URL + "/_DefaultFiles/defaultBranch.png";
export const DEFAULT_IMG_REVENUE =
  BASE_URL + "/_DefaultFiles/defaultBranch.png";
export const DEFAULT_IMG_EXPENSE =
  BASE_URL + "/_DefaultFiles/defaultBranch.png";
export const DEFAULT_IMG_CLIENT = BASE_URL + "/_DefaultFiles/defaultBranch.png";
export const STATUS = {
  SUCCESS: 200, // success operation
  INVALID_TOKEN: 498, //invalid token
  CATCH: 500, // catch
  NO_CONTENT: 204, // no data
  ERROR: 406, // validation
  VERSION: 306, // error version
};
export const PAGE_SIZE = 16;
export const IMPOSSIBLE_TOKEN = 0;
export const SERVICE_TYPE_NAME_MIN_LENGTH = 3;
export const SERVICE_TYPE_NAME_MAX_LENGTH = 50;
export const SERVICE_NAME_DESC = 500;
export const SCIENTIFIC_DEGREE_NAME_MIN_LENGTH = 3;
export const SCIENTIFIC_DEGREE_NAME_MAX_LENGTH = 50;
export const SCIENTIFIC_DEGREE_NAME_DESC = 500;
export const GENERAL_SPECIALIATY_NAME_MIN_LENGTH = 3;
export const GENERAL_SPECIALIATY_NAME_MAX_LENGTH = 50;
export const GENERAL_SPECIALIATY_NAME_DESC = 500;
export const SPECIAL_SPECIALIATY_NAME_MIN_LENGTH = 3;
export const SPECIAL_SPECIALIATY_NAME_MAX_LENGTH = 50;
export const SPECIAL_SPECIALIATY_NAME_DESC = 500;
export const SERVICE_MIN_LENGTH = 3;
export const SERVICE_MAX_LENGTH = 50;
export const SERVICE_DESC = 500;

export const NAME_MIN_LENGTH = 3;
export const NAME_MAX_LENGTH = 50;
export const TEXT_MAX_LENGTH = 500;

export const NEWS_MEDIA_TYPE_TOKEN_VIDEO = "MET-2";
export const NEWS_MEDIA_TYPE_TOKEN_IMAGE = "MET-1";
export const VIDEO_EXTENSIONS = [
  "mov",
  "avi",
  "wmv",
  "mp4",
  "m4p",
  "m4v",
  "ogg",
  "mpg",
  "mp2",
  "mpeg",
  "mpe",
  "mpv",
  "3gp",
  "flv",
];
