import Vue from "vue";
import VueRouter from "vue-router";
import { ifAuthenticated, ifNotAuthenticated } from "../utils/functions";

import Test from "../views/user/Test.vue";
import Home from "../views/home/Home.vue";
import Login from "../views/user/Login.vue";
// import ForgetPassword from "../views/user/ForgetPassword.vue";

import Employees from "../views/employees/employees/Employees.vue";
import EmployeeAdd from "../views/employees/employees/EmployeeAdd.vue";
import EmployeeEdit from "../views/employees/employees/EmployeeEdit.vue";

import EmployeeSalarySettings from "../views/employees/employeeSalarySettings/EmployeeSalarySettings.vue";
import EmployeeSalarySettingAdd from "../views/employees/employeeSalarySettings/EmployeeSalarySettingAdd.vue";
import EmployeeSalarySettingEdit from "../views/employees/employeeSalarySettings/EmployeeSalarySettingEdit.vue";

import EmployeeWorkSchedules from "../views/employees/employeeWorkSchedules/EmployeeWorkSchedules.vue";
import EmployeeWorkScheduleAdd from "../views/employees/employeeWorkSchedules/EmployeeWorkScheduleAdd.vue";

import EmployeeOfficialHolidaysPlans from "../views/employees/employeeOfficialHolidaysPlans/EmployeeOfficialHolidaysPlans.vue";
import EmployeeOfficialHolidaysPlanAdd from "../views/employees/employeeOfficialHolidaysPlans/EmployeeOfficialHolidaysPlanAdd.vue";

import EmployeesInputSheetsAttendance from "../views/employees/employeesInputSheetsAttendance/EmployeesInputSheetsAttendance.vue";
import EmployeesInputSheetAttendanceAdd from "../views/employees/employeesInputSheetsAttendance/EmployeesInputSheetAttendanceAdd.vue";

import EmployeesInputSheetAttendanceMerges from "../views/employees/employeesInputSheetAttendanceMerges/EmployeesInputSheetAttendanceMerges.vue";
import EmployeesInputSheetAttendanceMergeAdd from "../views/employees/employeesInputSheetAttendanceMerges/EmployeesInputSheetAttendanceMergeAdd.vue";

import EmployeeAttendance from "../views/employeesAttendance/EmployeeAttendance.vue";
import EmployeeAttendanceAdd from "../views/employeesAttendance/EmployeeAttendanceAdd.vue";
import EmployeeAttendanceEdit from "../views/employeesAttendance/EmployeeAttendanceEdit.vue";

import EmployeeMovement from "../views/employeesMovement/EmployeeMovement.vue";
import EmployeeMovementAdd from "../views/employeesMovement/EmployeeMovementAdd.vue";
import EmployeeMovementEdit from "../views/employeesMovement/EmployeeMovementEdit.vue";

import EmployeeMovementMedia from "../views/employeesMovementMedia/EmployeeMovementMedia.vue";
import EmployeeMovementMediaInfo from "../views/employeesMovementMedia/EmployeeMovementMediaInfo.vue";

import AttendanceHistory from "../views/attendance/AttendanceHistory.vue";
import AttendanceAttend from "../views/attendance/AttendanceAttend.vue";
import AttendanceDeparture from "../views/attendance/AttendanceDeparture.vue";

import Points from "../views/points/Points.vue";
import EmployeePoints from "../views/points/EmployeePoints.vue";

import EmployeeSlices from "../views/slices/EmployeeSlices.vue";
import EmployeeSlicesAdd from "../views/slices/EmployeeSlicesAdd.vue";

import Reports from "../views/reports/Reports.vue";

import Privilege from "../views/privilege/Privilege.vue";

import Branches from "../views/branches/Branches.vue";
import BranchAdd from "../views/branches/BranchAdd.vue";
import BranchEdit from "../views/branches/BranchEdit.vue";

import BranchesTimes from "../views/branchesTimes/BranchesTimes.vue";
import BranchTimeAdd from "../views/branchesTimes/BranchTimeAdd.vue";
import BranchTimeEdit from "../views/branchesTimes/BranchTimeEdit.vue";

import Stores from "../views/stores/Stores.vue";
import StoreAdd from "../views/stores/StoreAdd.vue";
import StoreEdit from "../views/stores/StoreEdit.vue";

import StoresTimes from "../views/storesTimes/StoresTimes.vue";
import StoreTimeAdd from "../views/storesTimes/StoreTimeAdd.vue";
import StoreTimeEdit from "../views/storesTimes/StoreTimeEdit.vue";

import Treasuries from "../views/finance/treasuries/Treasuries.vue";
import TreasuryAdd from "../views/finance/treasuries/TreasuryAdd.vue";
import TreasuryEdit from "../views/finance/treasuries/TreasuryEdit.vue";

import News from "../views/news/News.vue";
import NewsAdd from "../views/news/NewsAdd.vue";
import NewsEdit from "../views/news/NewsEdit.vue";
import NewsItem from "../views/news/NewsItem.vue";

import TreasuriesTransactions from "../views/finance/treasuriesTransactions/TreasuriesTransactions.vue";
import TreasuryTransactionAdd from "../views/finance/treasuriesTransactions/TreasuryTransactionAdd.vue";

import Revenues from "../views/finance/revenues/Revenues.vue";
import RevenueAdd from "../views/finance/revenues/RevenueAdd.vue";

import Expenses from "../views/finance/expenses/Expenses.vue";
import ExpenseAdd from "../views/finance/expenses/ExpenseAdd.vue";

import Clients from "../views/clients/Clients.vue";
import ClientAdd from "../views/clients/ClientAdd.vue";
import ClientEdit from "../views/clients/ClientEdit.vue";

import SettingsEstablishment from "../views/settings/settingsEstablishment/SettingsEstablishment.vue";

import MonthsEstablishmentSettings from "../views/settings/settingsEstablishment/monthsSettings/MonthsEstablishmentSettings.vue";
import MonthsEstablishmentSettingAdd from "../views/settings/settingsEstablishment/monthsSettings/MonthsEstablishmentSettingAdd.vue";

import EstablishmentYearSettings from "../views/settings/settingsEstablishment/yearSettings/EstablishmentYearSettings.vue";
import EstablishmentYearSettingAdd from "../views/settings/settingsEstablishment/yearSettings/EstablishmentYearSettingAdd.vue";
import EstablishmentYearSettingEdit from "../views/settings/settingsEstablishment/yearSettings/EstablishmentYearSettingEdit.vue";

import SettingsOther from "../views/settings/settingsOther/SettingsOther.vue";

import Sectors from "../views/settings/settingsOther/sectors/Sectors.vue";
import SectorsAdd from "../views/settings/settingsOther/sectors/SectorsAdd.vue";
import SectorsEdit from "../views/settings/settingsOther/sectors/SectorsEdit.vue";

import Jobs from "../views/settings/settingsOther/jobs/Jobs.vue";
import JobsAdd from "../views/settings/settingsOther/jobs/JobsAdd.vue";
import JobsEdit from "../views/settings/settingsOther/jobs/JobsEdit.vue";

import Qualifications from "../views/settings/settingsOther/qualifications/Qualifications.vue";
import QualificationsAdd from "../views/settings/settingsOther/qualifications/QualificationsAdd.vue";
import QualificationsEdit from "../views/settings/settingsOther/qualifications/QualificationsEdit.vue";

import RevenuesTypes from "../views/settings/settingsOther/revenuesTypes/RevenuesTypes.vue";
import RevenueTypeAdd from "../views/settings/settingsOther/revenuesTypes/RevenueTypeAdd.vue";
import RevenueTypeEdit from "../views/settings/settingsOther/revenuesTypes/RevenueTypeEdit.vue";

import ExpensesTypes from "../views/settings/settingsOther/expensesTypes/ExpensesTypes.vue";
import ExpenseTypeAdd from "../views/settings/settingsOther/expensesTypes/ExpenseTypeAdd.vue";
import ExpenseTypeEdit from "../views/settings/settingsOther/expensesTypes/ExpenseTypeEdit.vue";

import ServicesTypes from "../views/settings/settingsOther/servicesTypes/ServicesTypes.vue";
import ServicesTypeAdd from "../views/settings/settingsOther/servicesTypes/ServicesTypeAdd.vue";
import ServiceTypeEdit from "../views/settings/settingsOther/servicesTypes/ServiceTypeEdit.vue";

import GeneralSpecialties from "../views/settings/settingsOther/generalSpecialties/GeneralSpecialties.vue";
import GeneralSpecialtyAdd from "../views/settings/settingsOther/generalSpecialties/GeneralSpecialtyAdd.vue";
import GeneralSpecialtyEdit from "../views/settings/settingsOther/generalSpecialties/GeneralSpecialtyEdit.vue";

import NewsCategories from "../views/settings/settingsOther/newsCategories/NewsCategories.vue";
import NewsCategoryAdd from "../views/settings/settingsOther/newsCategories/NewsCategoryAdd.vue";
import NewsCategoryEdit from "../views/settings/settingsOther/newsCategories/NewsCategoryEdit.vue";

import SpecialSpecialties from "../views/settings/settingsOther/specialSpecialties/SpecialSpecialties.vue";
import SpecialSpecialtyAdd from "../views/settings/settingsOther/specialSpecialties/SpecialSpecialtyAdd.vue";
import SpecialSpecialtyEdit from "../views/settings/settingsOther/specialSpecialties/SpecialSpecialtyEdit.vue";

import Services from "../views/settings/settingsOther/services/Services.vue";
import ServiceAdd from "../views/settings/settingsOther/services/ServiceAdd.vue";
import ServiceEdit from "../views/settings/settingsOther/services/ServiceEdit.vue";

import SettingsSalary from "../views/settings/settingsSalary/SettingsSalary.vue";

import WorkShifts from "../views/settings/settingsSalary/workShifts/WorkShifts.vue";
import WorkShiftAdd from "../views/settings/settingsSalary/workShifts/WorkShiftAdd.vue";
import WorkShiftEdit from "../views/settings/settingsSalary/workShifts/WorkShiftEdit.vue";

import WorkPlans from "../views/settings/settingsSalary/workPlans/WorkPlans.vue";
import WorkPlanAdd from "../views/settings/settingsSalary/workPlans/WorkPlanAdd.vue";
import WorkPlanEdit from "../views/settings/settingsSalary/workPlans/WorkPlanEdit.vue";

import WorkPlanDays from "../views/settings/settingsSalary/workPlanDays/WorkPlanDays.vue";

import SalaryEffects from "../views/settings/settingsSalary/salaryEffects/SalaryEffects.vue";
import SalaryEffectAdd from "../views/settings/settingsSalary/salaryEffects/SalaryEffectAdd.vue";
import SalaryEffectEdit from "../views/settings/settingsSalary/salaryEffects/SalaryEffectEdit.vue";

import OfficialHolidays from "../views/settings/settingsSalary/officialHolidays/OfficialHolidays.vue";
import OfficialHolidayAdd from "../views/settings/settingsSalary/officialHolidays/OfficialHolidayAdd.vue";
import OfficialHolidayEdit from "../views/settings/settingsSalary/officialHolidays/OfficialHolidayEdit.vue";

import BalancesPlans from "../views/settings/settingsSalary/balancesPlans/BalancesPlans.vue";
import BalancesPlanAdd from "../views/settings/settingsSalary/balancesPlans/BalancesPlanAdd.vue";
import BalancesPlanEdit from "../views/settings/settingsSalary/balancesPlans/BalancesPlanEdit.vue";
import BalancesPlanDetails from "../views/settings/settingsSalary/balancesPlans/BalancesPlanDetails.vue";
import BalancesPlanDetailsAdd from "../views/settings/settingsSalary/balancesPlans/BalancesPlanDetailsAdd.vue";
import BalancesPlanDetailsEdit from "../views/settings/settingsSalary/balancesPlans/BalancesPlanDetailsEdit.vue";

import Allowances from "../views/settings/settingsSalary/allowances/Allowances.vue";
import AllowanceAdd from "../views/settings/settingsSalary/allowances/AllowanceAdd.vue";
import AllowanceEdit from "../views/settings/settingsSalary/allowances/AllowanceEdit.vue";

import AllowancesPlans from "../views/settings/settingsSalary/allowancesPlans/AllowancesPlans.vue";
import AllowancesPlanAdd from "../views/settings/settingsSalary/allowancesPlans/AllowancesPlanAdd.vue";
import AllowancesPlanEdit from "../views/settings/settingsSalary/allowancesPlans/AllowancesPlanEdit.vue";
import AllowancesPlanDetails from "../views/settings/settingsSalary/allowancesPlans/AllowancesPlanDetails.vue";
import AllowancesPlanDetailsAdd from "../views/settings/settingsSalary/allowancesPlans/AllowancesPlanDetailsAdd.vue";
import AllowancesPlanDetailsEdit from "../views/settings/settingsSalary/allowancesPlans/AllowancesPlanDetailsEdit.vue";

import MonthAllowancesPlans from "../views/settings/settingsSalary/monthAllowancesPlans/MonthAllowancesPlans.vue";
import MonthAllowancesPlanAdd from "../views/settings/settingsSalary/monthAllowancesPlans/MonthAllowancesPlanAdd.vue";
import MonthAllowancesPlanEdit from "../views/settings/settingsSalary/monthAllowancesPlans/MonthAllowancesPlanEdit.vue";
import MonthAllowancesPlanDetails from "../views/settings/settingsSalary/monthAllowancesPlans/MonthAllowancesPlanDetails.vue";
import MonthAllowancesPlanDetailsAdd from "../views/settings/settingsSalary/monthAllowancesPlans/MonthAllowancesPlanDetailsAdd.vue";
import MonthAllowancesPlanDetailsEdit from "../views/settings/settingsSalary/monthAllowancesPlans/MonthAllowancesPlanDetailsEdit.vue";

import OverTimePlans from "../views/settings/settingsSalary/overTimePlans/OverTimePlans.vue";
import OverTimePlanAdd from "../views/settings/settingsSalary/overTimePlans/OverTimePlanAdd.vue";
import OverTimePlanEdit from "../views/settings/settingsSalary/overTimePlans/OverTimePlanEdit.vue";
import OverTimePlanDetails from "../views/settings/settingsSalary/overTimePlans/OverTimePlanDetails.vue";

import AbsencePlans from "../views/settings/settingsSalary/absencePlans/AbsencePlans.vue";
import AbsencePlanAdd from "../views/settings/settingsSalary/absencePlans/AbsencePlanAdd.vue";
import AbsencePlanEdit from "../views/settings/settingsSalary/absencePlans/AbsencePlanEdit.vue";
import AbsencePlanDetails from "../views/settings/settingsSalary/absencePlans/AbsencePlanDetails.vue";

import MainColumns from "../views/settings/settingsSalary/mainColumns/MainColumns.vue";
import MainColumnAdd from "../views/settings/settingsSalary/mainColumns/MainColumnAdd.vue";
import MainColumnEdit from "../views/settings/settingsSalary/mainColumns/MainColumnEdit.vue";

import InsurancesPlans from "../views/settings/settingsSalary/insurancesPlans/InsurancesPlans.vue";
import InsurancesPlanAdd from "../views/settings/settingsSalary/insurancesPlans/InsurancesPlanAdd.vue";
import InsurancesPlanEdit from "../views/settings/settingsSalary/insurancesPlans/InsurancesPlanEdit.vue";
import InsurancesPlanDetails from "../views/settings/settingsSalary/insurancesPlans/InsurancesPlanDetails.vue";
import InsurancesPlanDetailsAdd from "../views/settings/settingsSalary/insurancesPlans/InsurancesPlanDetailsAdd.vue";
import InsurancesPlanDetailsEdit from "../views/settings/settingsSalary/insurancesPlans/InsurancesPlanDetailsEdit.vue";

import OfficialHolidaysPlans from "../views/settings/settingsSalary/officialHolidaysPlans/OfficialHolidaysPlans.vue";
import OfficialHolidaysPlanAdd from "../views/settings/settingsSalary/officialHolidaysPlans/OfficialHolidaysPlanAdd.vue";
import OfficialHolidaysPlanEdit from "../views/settings/settingsSalary/officialHolidaysPlans/OfficialHolidaysPlanEdit.vue";
import OfficialHolidaysPlanDetails from "../views/settings/settingsSalary/officialHolidaysPlans/OfficialHolidaysPlanDetails.vue";
import OfficialHolidaysPlanDetailsAdd from "../views/settings/settingsSalary/officialHolidaysPlans/OfficialHolidaysPlanDetailsAdd.vue";
import OfficialHolidaysPlanDetailsEdit from "../views/settings/settingsSalary/officialHolidaysPlans/OfficialHolidaysPlanDetailsEdit.vue";

import CalculatePayroll from "../views/settings/settingsSalary/calculatePayroll/CalculatePayroll.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/test",
    name: "Test",
    component: Test,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: ifAuthenticated,
  },
  // {
  //   path: "/forget-password",
  //   name: "ForgetPassword",
  //   component: ForgetPassword,
  //   beforeEnter: ifAuthenticated,
  // },

  {
    path: "/employees",
    name: "Employees",
    component: Employees,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-employee",
    name: "EmployeeAdd",
    component: EmployeeAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-employee/:employeeToken",
    name: "EmployeeEdit",
    component: EmployeeEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/employee-salary-settings/:employeeToken",
    name: "EmployeeSalarySettings",
    component: EmployeeSalarySettings,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-employee-salary-setting",
    name: "EmployeeSalarySettingAdd",
    component: EmployeeSalarySettingAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-employee-salary-setting/:employeeSalarySettingToken",
    name: "EmployeeSalarySettingEdit",
    component: EmployeeSalarySettingEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/employee-work-schedules/:employeeToken",
    name: "EmployeeWorkSchedules",
    component: EmployeeWorkSchedules,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-employee-work-schedule",
    name: "EmployeeWorkScheduleAdd",
    component: EmployeeWorkScheduleAdd,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/employee-official-holidays-plans/:employeeToken",
    name: "EmployeeOfficialHolidaysPlans",
    component: EmployeeOfficialHolidaysPlans,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-employee-official-holidays-plan",
    name: "EmployeeOfficialHolidaysPlanAdd",
    component: EmployeeOfficialHolidaysPlanAdd,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/employees-input-sheets-attendance",
    name: "EmployeesInputSheetsAttendance",
    component: EmployeesInputSheetsAttendance,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-employees-input-sheet-attendance",
    name: "EmployeesInputSheetAttendanceAdd",
    component: EmployeesInputSheetAttendanceAdd,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/employees-input-sheet-attendance-merges",
    name: "EmployeesInputSheetAttendanceMerges",
    component: EmployeesInputSheetAttendanceMerges,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-employees-input-sheet-attendance-merge",
    name: "EmployeesInputSheetAttendanceMergeAdd",
    component: EmployeesInputSheetAttendanceMergeAdd,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/attendance-employee/:employeeToken",
    name: "EmployeeAttendance",
    component: EmployeeAttendance,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-attendance-employee/:employeeToken",
    name: "EmployeeAttendanceAdd",
    component: EmployeeAttendanceAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-attendance-employee/:employeeToken/:attendanceToken",
    name: "EmployeeAttendanceEdit",
    component: EmployeeAttendanceEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/movement-employee/:attendanceToken",
    name: "EmployeeMovement",
    component: EmployeeMovement,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-movement-employee/:attendanceToken",
    name: "EmployeeMovementAdd",
    component: EmployeeMovementAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-movement-employee/:attendanceToken/:movementToken",
    name: "EmployeeMovementEdit",
    component: EmployeeMovementEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/employee-movement-media/:movementToken",
    name: "EmployeeMovementMedia",
    component: EmployeeMovementMedia,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/employee-movement-media-info/:movementMediaToken",
    name: "EmployeeMovementMediaInfo",
    component: EmployeeMovementMediaInfo,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/attendance-history",
    name: "AttendanceHistory",
    component: AttendanceHistory,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-attendance",
    name: "AttendanceAttend",
    component: AttendanceAttend,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-departure",
    name: "AttendanceDeparture",
    component: AttendanceDeparture,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/points",
    name: "Points",
    component: Points,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/employee-points/:employeeToken",
    name: "EmployeePoints",
    component: EmployeePoints,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/employee-slices/:employeeToken",
    name: "EmployeeSlices",
    component: EmployeeSlices,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-employee-slice/:employeeToken",
    name: "EmployeeSlicesAdd",
    component: EmployeeSlicesAdd,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/privilege",
    name: "Privilege",
    component: Privilege,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/branches",
    name: "Branches",
    component: Branches,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-branch",
    name: "BranchAdd",
    component: BranchAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-branch/:branchToken",
    name: "BranchEdit",
    component: BranchEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/branches-times",
    name: "BranchesTimes",
    component: BranchesTimes,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-branch-time",
    name: "BranchTimeAdd",
    component: BranchTimeAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-branch-time/:branchTimeToken",
    name: "BranchTimeEdit",
    component: BranchTimeEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/stores",
    name: "Stores",
    component: Stores,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-store",
    name: "StoreAdd",
    component: StoreAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-store/:storeToken",
    name: "StoreEdit",
    component: StoreEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/stores-times",
    name: "StoresTimes",
    component: StoresTimes,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-store-time",
    name: "StoreTimeAdd",
    component: StoreTimeAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-store-time/:storeTimeToken",
    name: "StoreTimeEdit",
    component: StoreTimeEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/treasuries",
    name: "Treasuries",
    component: Treasuries,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-treasury",
    name: "TreasuryAdd",
    component: TreasuryAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-treasury/:treasuryToken",
    name: "TreasuryEdit",
    component: TreasuryEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/news",
    name: "News",
    component: News,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-news",
    name: "NewsAdd",
    component: NewsAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-news/:newsToken",
    name: "NewsEdit",
    component: NewsEdit,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/news-item/:newsToken",
    name: "NewsItem",
    component: NewsItem,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/treasuries-transactions",
    name: "TreasuriesTransactions",
    component: TreasuriesTransactions,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-treasury-transaction",
    name: "TreasuryTransactionAdd",
    component: TreasuryTransactionAdd,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/revenues",
    name: "Revenues",
    component: Revenues,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-revenue",
    name: "RevenueAdd",
    component: RevenueAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/expenses",
    name: "Expenses",
    component: Expenses,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-expense",
    name: "ExpenseAdd",
    component: ExpenseAdd,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/clients",
    name: "Clients",
    component: Clients,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-client",
    name: "ClientAdd",
    component: ClientAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-client/:clientToken",
    name: "ClientEdit",
    component: ClientEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/settings-establishment",
    name: "SettingsEstablishment",
    component: SettingsEstablishment,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/months-establishment-settings",
    name: "MonthsEstablishmentSettings",
    component: MonthsEstablishmentSettings,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-months-establishment-setting",
    name: "MonthsEstablishmentSettingAdd",
    component: MonthsEstablishmentSettingAdd,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/year-establishment-settings",
    name: "EstablishmentYearSettings",
    component: EstablishmentYearSettings,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-year-establishment-setting",
    name: "EstablishmentYearSettingAdd",
    component: EstablishmentYearSettingAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-year-establishment-setting/:establishmentYearSettingToken",
    name: "EstablishmentYearSettingEdit",
    component: EstablishmentYearSettingEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/settings-other",
    name: "SettingsOther",
    component: SettingsOther,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/sectors",
    name: "Sectors",
    component: Sectors,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-sector",
    name: "SectorsAdd",
    component: SectorsAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-sector/:sectorToken",
    name: "SectorsEdit",
    component: SectorsEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/jobs",
    name: "Jobs",
    component: Jobs,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-job",
    name: "JobsAdd",
    component: JobsAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-job/:jobToken",
    name: "JobsEdit",
    component: JobsEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/qualifications",
    name: "Qualifications",
    component: Qualifications,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-qualification",
    name: "QualificationsAdd",
    component: QualificationsAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-qualification/:qualificationToken",
    name: "QualificationsEdit",
    component: QualificationsEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/revenues-types",
    name: "RevenuesTypes",
    component: RevenuesTypes,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-revenue-type",
    name: "RevenueTypeAdd",
    component: RevenueTypeAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-revenue-type/:revenuesTypeToken",
    name: "RevenueTypeEdit",
    component: RevenueTypeEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/expenses-types",
    name: "ExpensesTypes",
    component: ExpensesTypes,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-expense-type",
    name: "ExpenseTypeAdd",
    component: ExpenseTypeAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-expense-type/:expensesTypeToken",
    name: "ExpenseTypeEdit",
    component: ExpenseTypeEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/services-types",
    name: "ServicesTypes",
    component: ServicesTypes,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-services-type",
    name: "ServicesTypeAdd",
    component: ServicesTypeAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-services-type/:serviceTypeToken",
    name: "ServiceTypeEdit",
    component: ServiceTypeEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/general-specialties",
    name: "GeneralSpecialties",
    component: GeneralSpecialties,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-general-specialty",
    name: "GeneralSpecialtyAdd",
    component: GeneralSpecialtyAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-general-specialty/:generalSpecialtyToken",
    name: "GeneralSpecialtyEdit",
    component: GeneralSpecialtyEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/news-categories",
    name: "NewsCategories",
    component: NewsCategories,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-news-category",
    name: "NewsCategoryAdd",
    component: NewsCategoryAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-news-category/:newsCategoryToken",
    name: "NewsCategoryEdit",
    component: NewsCategoryEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/special-specialties",
    name: "SpecialSpecialties",
    component: SpecialSpecialties,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-special-specialty",
    name: "SpecialSpecialtyAdd",
    component: SpecialSpecialtyAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-special-specialty/:specialSpecialtyToken",
    name: "SpecialSpecialtyEdit",
    component: SpecialSpecialtyEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/services",
    name: "Services",
    component: Services,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-service",
    name: "ServiceAdd",
    component: ServiceAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-service/:serviceToken",
    name: "ServiceEdit",
    component: ServiceEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/settings-salary",
    name: "SettingsSalary",
    component: SettingsSalary,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/work-shifts",
    name: "WorkShifts",
    component: WorkShifts,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-work-shift",
    name: "WorkShiftAdd",
    component: WorkShiftAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-work-shift/:workShiftToken",
    name: "WorkShiftEdit",
    component: WorkShiftEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/work-plans",
    name: "WorkPlans",
    component: WorkPlans,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-work-plan",
    name: "WorkPlanAdd",
    component: WorkPlanAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-work-plan/:workPlanToken",
    name: "WorkPlanEdit",
    component: WorkPlanEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/work-plan-days/:workPlanToken",
    name: "WorkPlanDays",
    component: WorkPlanDays,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/salary-effects",
    name: "SalaryEffects",
    component: SalaryEffects,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-salary-effect",
    name: "SalaryEffectAdd",
    component: SalaryEffectAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-salary-effect/:employeeSalaryEffectToken",
    name: "SalaryEffectEdit",
    component: SalaryEffectEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/official-holidays",
    name: "OfficialHolidays",
    component: OfficialHolidays,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-official-holiday",
    name: "OfficialHolidayAdd",
    component: OfficialHolidayAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-official-holiday/:officialHolidayToken",
    name: "OfficialHolidayEdit",
    component: OfficialHolidayEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/balances-plans",
    name: "BalancesPlans",
    component: BalancesPlans,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-balances-plan",
    name: "BalancesPlanAdd",
    component: BalancesPlanAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-balances-plan/:balancesPlansToken",
    name: "BalancesPlanEdit",
    component: BalancesPlanEdit,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/balances-plan-details/:balancesPlansToken",
    name: "BalancesPlanDetails",
    component: BalancesPlanDetails,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-balances-plan-details/:balancesPlansToken",
    name: "BalancesPlanDetailsAdd",
    component: BalancesPlanDetailsAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-balances-plan-details/:balancesPlansSlidesDetailsToken",
    name: "BalancesPlanDetailsEdit",
    component: BalancesPlanDetailsEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/allowances",
    name: "Allowances",
    component: Allowances,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-allowance",
    name: "AllowanceAdd",
    component: AllowanceAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-allowance/:allowanceToken",
    name: "AllowanceEdit",
    component: AllowanceEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/allowances-plans",
    name: "AllowancesPlans",
    component: AllowancesPlans,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-allowances-plan",
    name: "AllowancesPlanAdd",
    component: AllowancesPlanAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-allowances-plan/:allowancesPlansToken",
    name: "AllowancesPlanEdit",
    component: AllowancesPlanEdit,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/allowances-plan-details/:allowancesPlansToken",
    name: "AllowancesPlanDetails",
    component: AllowancesPlanDetails,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-allowances-plan-details/:allowancesPlansToken",
    name: "AllowancesPlanDetailsAdd",
    component: AllowancesPlanDetailsAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-allowances-plan-details/:allowancesPlansSlidesDetailsToken",
    name: "AllowancesPlanDetailsEdit",
    component: AllowancesPlanDetailsEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/month-allowances-plans",
    name: "MonthAllowancesPlans",
    component: MonthAllowancesPlans,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-month-allowances-plan",
    name: "MonthAllowancesPlanAdd",
    component: MonthAllowancesPlanAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-month-allowances-plan/:monthAllowancesPlansToken",
    name: "MonthAllowancesPlanEdit",
    component: MonthAllowancesPlanEdit,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/month-allowances-plan-details/:monthAllowancesPlansToken",
    name: "MonthAllowancesPlanDetails",
    component: MonthAllowancesPlanDetails,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-month-allowances-plan-details/:monthAllowancesPlansToken",
    name: "MonthAllowancesPlanDetailsAdd",
    component: MonthAllowancesPlanDetailsAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-month-allowances-plan-details/:monthAllowancesPlansSlidesDetailsToken",
    name: "MonthAllowancesPlanDetailsEdit",
    component: MonthAllowancesPlanDetailsEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/overtime-plans",
    name: "OverTimePlans",
    component: OverTimePlans,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-overtime-plan",
    name: "OverTimePlanAdd",
    component: OverTimePlanAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-overtime-plan/:overTimePlansToken",
    name: "OverTimePlanEdit",
    component: OverTimePlanEdit,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/overtime-plan-details/:overTimePlansToken",
    name: "OverTimePlanDetails",
    component: OverTimePlanDetails,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/absence-plans",
    name: "AbsencePlans",
    component: AbsencePlans,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-absence-plan",
    name: "AbsencePlanAdd",
    component: AbsencePlanAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-absence-plan/:absencePlansToken",
    name: "AbsencePlanEdit",
    component: AbsencePlanEdit,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/absence-plan-details/:absencePlansToken",
    name: "AbsencePlanDetails",
    component: AbsencePlanDetails,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/main-columns",
    name: "MainColumns",
    component: MainColumns,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-main-column",
    name: "MainColumnAdd",
    component: MainColumnAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-main-column/:mainColumnToken",
    name: "MainColumnEdit",
    component: MainColumnEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/insurances-plans",
    name: "InsurancesPlans",
    component: InsurancesPlans,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-insurances-plan",
    name: "InsurancesPlanAdd",
    component: InsurancesPlanAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-insurances-plan/:insurancesPlansToken",
    name: "InsurancesPlanEdit",
    component: InsurancesPlanEdit,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/insurances-plan-details/:insurancesPlansToken",
    name: "InsurancesPlanDetails",
    component: InsurancesPlanDetails,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-insurances-plan-details/:insurancesPlansToken",
    name: "InsurancesPlanDetailsAdd",
    component: InsurancesPlanDetailsAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-insurances-plan-details/:insurancesPlansSlidesDetailsToken",
    name: "InsurancesPlanDetailsEdit",
    component: InsurancesPlanDetailsEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/official-holidays-plans",
    name: "OfficialHolidaysPlans",
    component: OfficialHolidaysPlans,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-official-holidays-plan",
    name: "OfficialHolidaysPlanAdd",
    component: OfficialHolidaysPlanAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-official-holidays-plan/:officialHolidaysPlansToken",
    name: "OfficialHolidaysPlanEdit",
    component: OfficialHolidaysPlanEdit,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/official-holidays-plan-details/:officialHolidaysPlansToken",
    name: "OfficialHolidaysPlanDetails",
    component: OfficialHolidaysPlanDetails,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-official-holidays-plan-details/:officialHolidaysPlansToken",
    name: "OfficialHolidaysPlanDetailsAdd",
    component: OfficialHolidaysPlanDetailsAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-official-holidays-plan-details/:officialHolidaysPlansSlidesDetailsToken",
    name: "OfficialHolidaysPlanDetailsEdit",
    component: OfficialHolidaysPlanDetailsEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/calculate-payroll",
    name: "CalculatePayroll",
    component: CalculatePayroll,
    beforeEnter: ifNotAuthenticated,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
