<template>
  <div>
    <!-- <CustomDatePicker /> -->
  </div>
</template>

<script>
// import CustomDatePicker from "@/components/general/CustomDatePicker.vue";
import Car from "@/utils/Car.js";
export default {
  data() {
    return {
      phNo: "",
    };
  },
  components: {
    // CustomDatePicker,
  },
  methods: {},
  created() {
    let car1 = new Car("Toyota", "Red");
    // let car1 = new Car();
    // console.log(Car.prototype.getDetails());
    console.log(car1.getDetails());
  },
};
</script>
